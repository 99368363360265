import {  mimeDict } from "../types/Master";

export const getfileType = (extension: string): string => {
    switch (extension) {
        case "txt":
            return mimeDict.txt;
            break;
        case "pdf":
            return mimeDict.pdf;
            break;
        case "docx":
            return mimeDict.docx;
            break;
        case "xlsx":
            return mimeDict.xlsx;
            break;
        case "png":
            return mimeDict.png;
            break;
        case "jpeg":
            return mimeDict.jpeg;
            break;
        case "gif":
            return mimeDict.gif;
            break;
        case "rar":
            return mimeDict.rar;
            break;
        default:
            return mimeDict.stream;
    }
};
