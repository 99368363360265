import * as React from "react";
import {
    Grid,
    List,
    ListItemText,
    ListItemIcon,
} from "@material-ui/core";
import UseTranslation from "../services/UseTranslation";
const translation = UseTranslation();
import ListIcon1 from '../images/Andetdba-s.svg';
import ListIcon2 from '../images/Profilogemailsdba-s.svg';
import ListIcon3 from '../images/dbaplusdba-s.svg';
import ListIcon4 from '../images/nemiddba-s.svg';
import ListIcon5 from '../images/Reglerogvilkrdba-s.svg';
import ListIcon6 from '../images/FragtPakkelabeldba-s.svg';
import ListIcon7 from '../images/Svindelogsikkerheddba-s.svg';
import ListIcon8 from '../images/koebdba-s.svg';
import ListIcon9 from '../images/saelgdba-s.svg';
import { Link } from "react-router-dom";
import { categories } from "../types/Master";

class Home extends React.Component {
    render() {
        return (
            <Grid item xs={12} className="featured_product">
                <List className="row dba-homeContainer" component={"div"}>
                    <Link
                        className="flex-column col-4 mb-2 text-center"

                        to={"/articlesList/"+categories.Andet}
                    >
                        <ListItemIcon
                            style={{ display: "inline-block", textAlign: "center" }}
                        >
                            <img
                                src={ListIcon1}
                                className="img-fluid"
                            />
                        </ListItemIcon>
                        <ListItemText primary="Andet" />
                    </Link>
                    <Link
                        className="flex-column col-4 mb-2 text-center"

                        to={"/articlesList/" + categories["Profil og emails"]}
                    >
                        <ListItemIcon
                            style={{ display: "inline-block", textAlign: "center" }}
                        >
                            <img
                                src={ListIcon2}
                                className="img-fluid"
                            />
                        </ListItemIcon>
                        <ListItemText primary="Profil og e-mails" />
                    </Link>
                    <Link
                        className="flex-column col-4 mb-2 text-center"

                        to={"/articlesList/" + categories["DBA+"]}
                    >
                        <ListItemIcon
                            style={{ display: "inline-block", textAlign: "center" }}
                        >
                            <img
                                src={ListIcon3}
                                className="img-fluid"
                            />
                        </ListItemIcon>
                        <ListItemText primary="DBA+" />
                    </Link>
                    <Link
                        className="flex-column col-4 text-center"

                        to={"/articlesList/" + categories["MitID"]}
                    >
                        <ListItemIcon
                            style={{ display: "inline-block", textAlign: "center" }}
                        >
                            <img
                                src={ListIcon4}
                                className="img-fluid bi-fullWidth"
                            />
                        </ListItemIcon>
                        <ListItemText primary="MitID" />
                    </Link>
                    <Link
                        className="flex-column col-4 text-center"

                        to={"/articlesList/" + categories["Regler"]}
                    >
                        <ListItemIcon
                            style={{ display: "inline-block", textAlign: "center" }}
                        >
                            <img
                                src={ListIcon5}
                                className="img-fluid"
                            />
                        </ListItemIcon>
                        <ListItemText primary={translation.Regler} />
                    </Link>
                    <Link
                        className="flex-column col-4 text-center"

                        to={"/articlesList/" + categories["Fragt/Pakkelabel"]}
                    >
                        <ListItemIcon
                            style={{ display: "inline-block", textAlign: "center" }}
                        >
                            <img
                                src={ListIcon6}
                                className="img-fluid"
                            />
                        </ListItemIcon>
                        <ListItemText primary="Fragt/Pakkelabel" />
                    </Link>
                    <Link
                        className="flex-column col-4 text-center"

                        to={"/articlesList/" + categories["Svindel og sikkerhed"]}
                    >
                        <ListItemIcon
                            style={{ display: "inline-block", textAlign: "center" }}
                        >
                            <img
                                src={ListIcon7}
                                className="img-fluid"
                            />
                        </ListItemIcon>
                        <ListItemText primary="Svindel og sikkerhed" />
                    </Link>
                    <Link
                        className="flex-column col-4 text-center"

                        to={"/articlesList/" + categories["kob"]}
                    >
                        <ListItemIcon
                            style={{ display: "inline-block", textAlign: "center" }}
                        >
                            <img
                                src={ListIcon8}
                                className="img-fluid"
                            />
                        </ListItemIcon>
                        <ListItemText primary={translation.kob} />
                    </Link>
                    <Link
                        className="flex-column col-4 text-center"

                        to={"/articlesList/" + categories["Saelg"]}
                    >
                        <ListItemIcon
                            style={{ display: "inline-block", textAlign: "center" }}
                        >
                            <img
                                src={ListIcon9}
                                className="img-fluid"
                            />
                        </ListItemIcon>
                        <ListItemText primary={translation.Saelg} />
                    </Link>
                </List>
            </Grid>
        );
    }
}

export default Home;
