import * as React from "react";
import { Box, List, ListItem, Grid } from "@material-ui/core";
import "./index.css";
import { ApplicationState } from "../types/Master";
import UseTranslation from "../services/UseTranslation";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const mapStateToProps = (state: ApplicationState) => ({
  kbArticles: state.kbarticles,
});

const translation = UseTranslation();

let Limitarticles = 12;

const Articles = (props: any) => {
  const { kbArticles } = props;

  return (
    <Box className="article-section">
      <h2 className="light-grey-color-02">{translation.FaqTitle}</h2>
      <Grid className="article-bg-section">
        <List>
          {kbArticles.slice(0, Limitarticles).map((item: any, index: any) => {
            return (
              <ListItem>
                    <Link to={"/articledetails/" + item.PortalUrl}>
                  {renderHtml(item.Title)}
                </Link>
              </ListItem>
            );
          })}
        </List>
      </Grid>
      {kbArticles && kbArticles.length > 12 && (
        <Link to="/ArticlesList/all" className="view-all ">
          {translation.viewAll} ({kbArticles.length})
        </Link>
      )}
    </Box>
  );
};

function renderHtml(val: string) {
  return <div dangerouslySetInnerHTML={{ __html: val }} />;
}

export default connect(mapStateToProps)(Articles);
