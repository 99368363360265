import * as React from "react";
import { Container, Navbar } from "reactstrap";
import { Box, Typography, Backdrop } from "@material-ui/core";
import "./index.css";
import UseTranslation from "../services/UseTranslation";
import { Link } from "react-router-dom"; 
import Autocompletion from "../components/AutoComplete";
import DbaLogo from '../images/logo.svg';
const translation = UseTranslation();

const Header = (props: any) => {
 
    return (
        <React.Fragment>
            <Navbar className="header-section border-bottom">
                <Container>
                   
                    <Box className="w-100">
                        <a target="_blank" href="https://www.dba.dk" className="bi-flex--justifyEnd text-decoration-none bi-bold-14">
                            {translation.backToHomepage}
                        </a>
                    </Box>
                    <header className="row py-2 w-100">
                        <Box className="logo d-flex align-items-center col-12 col-sm-6">
                            <Link
                                to="/"
                                className="d-flex align-items-center text-dark text-decoration-none"
                            >
                                <img
                                    src={DbaLogo}
                                    alt="Bildba"
                                    title="Bildba"
                                    className="img-fluid mainlogo"
                                />
                                <span className="logoLabel">{translation.customerservice}</span>
                            </Link>
                        </Box>

                        <form className="col-12 col-sm-6">
                            <Typography className="mb-2">
                                {translation.SearchTitle}
                            </Typography>
                            <Box className="search-main d-flex">
                                <Autocompletion />
                            </Box>
                           
                        </form>
                    </header>
                </Container>
            </Navbar>
        </React.Fragment>
    )
}; 
export default Header;
