import React, { useEffect, useRef } from "react";
import { GetArticles } from "../services/CustomerService";
import UseTranslation from "../services/UseTranslation";
import { ApplicationState, article } from "../types/Master";
import { TextField, Backdrop, Box, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { action_types } from "../types/ActionTypes";
import { Dispatch } from "redux";
import { Link, useHistory } from "react-router-dom";
import { SearchOutlined } from "@material-ui/icons";

const translation = UseTranslation();

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.isLoading,
  kbArticles: state.kbarticles,
});

const mapDispatchToProps = (dispatch: Dispatch<action_types>) => {
  return {
    setLoader: (val: boolean) => dispatch({ type: "SET_LOADER", payload: val }),
    setKbArticles: (val: article[]) =>
      dispatch({ type: "SET_KB_ARTICLES", payload: val }),
  };
};

const useOutsideAlerter = (ref: any, handleClear: () => void) => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        handleClear();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

const Autocompletecontrol = (props: any) => {
  const { kbArticles, setKbArticles } = props;
  const [isLoading, setIsLoading] = React.useState(true);
  const [inputValue, setInputValue] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const [filteredArticles, setFilteredArticles] = React.useState<article[]>([]);
  const wrapperRef = useRef(null);

  const handleClear = () => {
    setOpen(false);
    setInputValue("");
  };

  useOutsideAlerter(wrapperRef, handleClear);

  const onSearchClick = () => {
    handleClear();
    if (inputValue != "" && filteredArticles.length > 0) {
      history.push("/articlesList/" + inputValue);
    }
  };

  const onKeyPress = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleClear();
      if (inputValue != "" && filteredArticles.length > 0) {
        history.push("/articlesList/" + inputValue);
      }
    }
  };

  React.useEffect(() => {
    setOpen(false);
    setInputValue("");
    if (kbArticles.length == 0) {
      setIsLoading(true);
      GetArticles()
        .then((res) => {
          if (res) {
            setKbArticles(res);
            setIsLoading(false);
          }
        })
        .catch((ex) => {
          setIsLoading(false);
        });
    }
  }, []);

  const handleSearch = (val: string) => {
    setInputValue(val);
    setFilteredArticles([]);
    let listOfWords = val.split(" ");
    if (val != "") {
      setOpen(true);
      let filtered: article[] = [];
      kbArticles.forEach((item: article) => {
        let isAllWordsPresent = false;
        listOfWords.every((word: string) => {
          if (
            item.Content.toLowerCase().includes(word.toLowerCase()) ||
            item.Title.toLowerCase().includes(word.toLowerCase())
          ) {
            isAllWordsPresent = true;
            return true;
          } else {
            isAllWordsPresent = false;
            return false;
          }
        });

        if (isAllWordsPresent) filtered.push(item);
      });
      filtered.length > 0 && setFilteredArticles(filtered);
    } else setOpen(false);
  };

  return (
    <React.Fragment>
      <Backdrop style={{ zIndex: 2250 }} open={isLoading}>
        <Box className="loading-icon" />
      </Backdrop>
      <div ref={wrapperRef}>
        <TextField
          required
          fullWidth
          id="Search"
          placeholder={translation.SearchPlaceholder}
          className="autocomplete-search"
          variant="outlined"
          value={inputValue}
          onChange={(e) => handleSearch(e.target.value as string)}
          onKeyDown={onKeyPress}
          InputProps={{
            endAdornment: (
              <IconButton
                disabled={inputValue != "" && filteredArticles.length == 0}
                className="header-searchIcon"
                onClick={onSearchClick}
              >
                <SearchOutlined />
              </IconButton>
            ),
          }}
        />
        {open && filteredArticles && (
          <Box className="search-results">
            {filteredArticles.map((option) => {
              return (
                <Link
                  className="px-2 py-2 search-results-options"
                  onClick={handleClear}
                  to={"/ArticleDetails/" + option.PortalUrl}
                >
                  {option.Title}
                </Link>
              );
            })}
            {inputValue != "" && filteredArticles.length == 0 && (
              <Box className="py-1 search-results-options">
                <div className="px-2">{translation.noResults}</div>
              </Box>
            )}
          </Box>
        )}
      </div>
    </React.Fragment>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Autocompletecontrol);
