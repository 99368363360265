import {
  Box,
  Button,
  Card,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Checkbox,
  FormControl,
  Backdrop,
} from "@material-ui/core";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { CreateEmailCase } from "../services/CustomerService";
import UseTranslation from "../services/UseTranslation";
import { getfileType } from "../services/Shared";
import Defines from "../config";
import { connect } from "react-redux";
import { ApplicationState } from "../types/Master";
import { Dispatch } from "redux";
import { action_types, SET_SUCCESS } from "../types/ActionTypes";
const translation = UseTranslation();
declare var grecaptcha: any;
const mapStateToProps = (state: ApplicationState) => ({
  formSuccess: state.formSuccess,
});
const mapDispatchToProps = (dispatch: Dispatch<action_types>) => {
  return {
    SetSuccess: (val: boolean) =>
      dispatch({ type: "SET_SUCCESS", payload: val }),
  };
};

const EmailForm = (props: any) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [Navn, setNavn] = React.useState<string>("");
  const [Email, setEmail] = React.useState<string>("");
  const [Telefonnummer, setTelefonnummer] = React.useState<string>("");
  const [Kategori, setKategori] = React.useState<string>("");
  const [Emne, setEmne] = React.useState<string>("");
  const [Beskrivelse, setBeskrivelse] = React.useState<string>("");
  const [isAcceptReturnPolicy, setIsAcceptReturnPolicy] =
    useState<boolean>(false);
  const [isFraudForm, setFraudForm] = useState<boolean>(false);
  const [Svindletprodukt, setSvindletProdukt] = React.useState<string>("");
  const [ScamAmount, setScamAmount] = React.useState<number>(0);
  const [Betalingsdato, setBetalingsdato] = React.useState<string>("");
  const [Betalingsform, setBetalingsform] = React.useState<number>(0);
  const [SellerName, setSellerName] = React.useState<string>("");
  const [SellerAddress, setSellerAddress] = React.useState<string>("");
  const [sellerPhone, setSellerPhone] = React.useState<string>("");
  const [SellerEmail, setSellerEmail] = React.useState<string>("");
  const [LinktoAd, setLinktoAd] = React.useState<string>("");
  const [LinktoProfile, setLinktoProfile] = React.useState<string>("");
  const [EmailValidationMessage, setEmailValidationMessage] =
    React.useState<string>("");
  const [SellerEmailValidationMessage, setSellerEmailValidationMessage] =
    React.useState<string>("");
  const [LinktoAdValidationMessage, setLinktoAdValidationMessage] =
    React.useState<string>("");
  const [LinktoProfileValidationMessage, setLinktoProfileValidationMessage] =
    React.useState<string>("");
  const [ScamAmountMessage, setScamAmountMessage] = React.useState<string>("");
  const [ErrorMessage, setErrorMessage] = React.useState<string>("");
  const [Platform, setPlatform] = React.useState<string>("");
  const [files, setFiles] = useState<FileList | null>();
  const [isFileSizeExceeded, setFileSizeExceeded] = useState<boolean>(false);
  const scamAmountRef = React.useRef<HTMLInputElement>(null);
  const [dateValidationMessage, setDateValidationMessage] =
    React.useState<string>("");

  const handleFilesAdded = (event: React.ChangeEvent<HTMLInputElement>) => {
    let files = event.target.files;
    if (files && files.length > 0 && files[0].size > 25 * 1024 * 1024) {
      setFileSizeExceeded(true);
    } else {
      setFiles(event.target.files);
      setFileSizeExceeded(false);
    }
  };
  const history = useHistory();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validate()) {
      const data = new FormData(event.currentTarget);
      setIsLoading(true);

      if (files && files.length > 0) {
        let file = files[0];
        let fileName = file.name;
        let extension = fileName.split(".").pop();
        let fileType = extension
          ? getfileType(extension)
          : "application/octet-stream";
        data.append("fileName", fileName);
        data.append("file", file);
        data.append("contentType", fileType);
      }

      grecaptcha.ready(function () {
        grecaptcha
          .execute(Defines.reCAPTCHA_SITE_KEY, { action: "submit" })
          .then(function (token: any) {
            data.append("token", token);
            CreateEmailCase(data)
              .then((res) => {
                setIsLoading(false);
                if (res.status == "Queue_Error") {
                  props.SetSuccess(false);
                  setToDefaults();
                  alert(
                    "Submit action Failed. Please refresh page and try again."
                  );
                } else if (res.status == "Recaptcha_Error") {
                  props.SetSuccess(false);
                  setErrorMessage(translation.recaptchafailuremsg);
                } else if (res.status != "Error") {
                  props.SetSuccess(true);
                  setToDefaults();
                } else {
                  props.SetSuccess(false);
                  setErrorMessage(translation.techinicalerrormsg);
                }
              })
              .catch((ex) => {
                setIsLoading(false);
                props.SetSuccess(false);
                setErrorMessage(translation.techinicalerrormsg);
                console.log(ex);
              });
          });
      });
    }
  };

  const validate = () => {
    let isValid = true;
    setErrorMessage("");
    setEmailValidationMessage("");
    setSellerEmailValidationMessage("");
    setLinktoAdValidationMessage("");
    setLinktoProfileValidationMessage("");
    setScamAmountMessage("");
    setDateValidationMessage("");

    const minDate = new Date("1753-01-01");
    const maxDate = new Date("9999-01-01");
    const selectedDate = new Date(Betalingsdato);
    if (selectedDate > maxDate || selectedDate < minDate) {
      setDateValidationMessage(translation.InvalidDate);
      isValid = false;
    }

    if (Email != "") {
      const isEmailValid = /@/.test(Email);
      if (!isEmailValid) {
        setEmailValidationMessage(translation.InvalidEmailAddress);
        isValid = false;
      }
    }
    if (Kategori == "Jeg er blevet snydt") {
      if (SellerEmail != "") {
        const isSellerEmailValid = /@/.test(SellerEmail);
        if (!isSellerEmailValid) {
          setSellerEmailValidationMessage(translation.InvalidEmailAddress);
          isValid = false;
        }
      }
      if (LinktoAd != "") {
        var isLinkVerified = LinktoAd.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        if (!isLinkVerified) {
          setLinktoAdValidationMessage(translation.InvalidUrl);
          isValid = false;
        }
      }
      if (LinktoProfile != "") {
        var isLinkVerified = LinktoProfile.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        if (!isLinkVerified) {
          setLinktoProfileValidationMessage(translation.InvalidUrl);
          isValid = false;
        }
      }
      if (ScamAmount != 0) {
        var numberPattern = /^[0-9]*$/;
        var returnvalue = numberPattern.test(ScamAmount.toString());
        if (!returnvalue) {
          setScamAmountMessage(translation.scamAmountValidationMessage);
          isValid = false;
          scamAmountRef.current && scamAmountRef.current.scrollIntoView();
          return isValid;
        }
      }
    }
    return isValid;
  };

  const setToDefaults = () => {
    setNavn("");
    setEmail("");
    setTelefonnummer("");
    setKategori("");
    setEmne("");
    setBeskrivelse("");
    setSvindletProdukt("");
    setScamAmount(0);
    setBetalingsdato("");
    setBetalingsform(0);
    setSellerName("");
    setSellerAddress("");
    setSellerPhone("");
    setSellerEmail("");
    setLinktoAd("");
    setLinktoProfile("");
    setFiles(null);
    setIsAcceptReturnPolicy(false);
    setFileSizeExceeded(false);
    setEmailValidationMessage("");
    setSellerEmailValidationMessage("");
    setLinktoAdValidationMessage("");
    setLinktoProfileValidationMessage("");
    setPlatform("");
  };

  useEffect(() => {
    if (Kategori == "Jeg er blevet snydt") {
      setFraudForm(true);
    } else {
      setFraudForm(false);
    }
  });

  const onLukClick = () => {
    history.push("/");
  };

  return (
    <React.Fragment>
      <Box>
        <Backdrop
          style={{
            zIndex: 2250,
          }}
          open={isLoading}
        >
          <Box className="loading-icon" />
        </Backdrop>
        {!props.formSuccess && (
          <Box
            component="form"
            className="form-section"
            onSubmit={handleSubmit}
          >
            <React.Fragment>
              <h3 className="mb-3 light-grey-color-02">
                {"Send en email til Kundeservice"}
              </h3>
            </React.Fragment>

            <Card className="">
              <TextField
                required
                id="Navn-textbox"
                label="Navn"
                name="Navn"
                variant="outlined"
                fullWidth
                value={Navn}
                onChange={(e) => setNavn(e.target.value as string)}
                onInvalid={(e) =>
                  (e.target as HTMLInputElement).setCustomValidity(
                    "Udfyld venligst dette felt"
                  )
                }
                onInput={(e) =>
                  (e.target as HTMLInputElement).setCustomValidity("")
                }
              />
              <div className="bi-errorMessageBlock">
                <TextField
                  required
                  id="Email-textbox"
                  label="Email"
                  name="Email"
                  variant="outlined"
                  fullWidth
                  value={Email}
                  onChange={(e) => setEmail(e.target.value as string)}
                  onInvalid={(e) =>
                    (e.target as HTMLInputElement).setCustomValidity(
                      "Udfyld venligst dette felt"
                    )
                  }
                  onInput={(e) =>
                    (e.target as HTMLInputElement).setCustomValidity("")
                  }
                />
                <span className="text-danger">{EmailValidationMessage} </span>
              </div>
              <TextField
                required
                id="Telefonnummer-textbox"
                label="Telefonnummer"
                name="Telefonnummer"
                variant="outlined"
                fullWidth
                value={Telefonnummer}
                onKeyPress={(e) =>
                  !/^[ +()0-9]+$/.test(e.key) && e.preventDefault()
                }
                onChange={(e) => setTelefonnummer(e.target.value as string)}
                onInvalid={(e) =>
                  (e.target as HTMLInputElement).setCustomValidity(
                    "Udfyld venligst dette felt"
                  )
                }
                onInput={(e) =>
                  (e.target as HTMLInputElement).setCustomValidity("")
                }
              />
              <FormControl fullWidth className="contact-select mb-4">
                <InputLabel htmlFor="select-case-type">Kategori *</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="contact-select-type"
                  name="Kategori"
                  label={"Kategori *"}
                  fullWidth
                  className="dropdown"
                  style={{ minWidth: 120 }}
                  required
                  value={Kategori}
                  onChange={(e) => {
                    e.preventDefault();
                    setKategori(e.target.value as string);
                  }}
                  onInvalid={(e) => {
                    if (Kategori !== "") {
                      (e.target as HTMLInputElement).setCustomValidity("");
                      e.preventDefault();
                    } else {
                      (e.target as HTMLInputElement).setCustomValidity(
                        "Udfyld venligst dette felt"
                      );
                    }
                  }}
                >
                  <MenuItem value={""}>- Ingen -</MenuItem>
                  <MenuItem value={"Andet"}>Andet</MenuItem>
                  <MenuItem value={"DBA+"}>DBA+</MenuItem>
                  <MenuItem value={"Fragt/Pakkelabel"}>
                    Fragt/Pakkelabel
                  </MenuItem>
                  <MenuItem value={"Jeg er blevet snydt"}>
                    Jeg er blevet snydt
                  </MenuItem>
                  <MenuItem value={translation.kob}>{translation.kob}</MenuItem>
                  <MenuItem value={"MitID"}>MitID</MenuItem>
                  <MenuItem value={"Profil og emails"}>
                    Profil og emails
                  </MenuItem>
                  <MenuItem value={translation.Regler}>
                    {translation.Regler}
                  </MenuItem>
                  <MenuItem value={translation.Saelg}>
                    {translation.Saelg}
                  </MenuItem>
                  <MenuItem value={"Svindel og sikkerhed"}>
                    Svindel og sikkerhed
                  </MenuItem>
                </Select>
              </FormControl>
              {isFraudForm && (
                <Card className="">
                  <TextField
                    required
                    id="Svindlet produkt-textbox"
                    label="Svindlet produkt"
                    name="Svindletprodukt"
                    variant="outlined"
                    fullWidth
                    value={Svindletprodukt}
                    onChange={(e) =>
                      setSvindletProdukt(e.target.value as string)
                    }
                    onInvalid={(e) =>
                      (e.target as HTMLInputElement).setCustomValidity(
                        "Udfyld venligst dette felt"
                      )
                    }
                    onInput={(e) =>
                      (e.target as HTMLInputElement).setCustomValidity("")
                    }
                  />
                  <div className="bi-errorMessageBlock">
                    <TextField
                      required
                      id="scamAmount-textbox"
                      label={translation.scamAmount}
                      name="ScamAmount"
                      variant="outlined"
                      fullWidth
                      value={ScamAmount}
                      ref={scamAmountRef}
                      onChange={(e) =>
                        setScamAmount(e.target.value as unknown as number)
                      }
                      onInvalid={(e) =>
                        (e.target as HTMLInputElement).setCustomValidity(
                          "Udfyld venligst dette felt"
                        )
                      }
                      onInput={(e) =>
                        (e.target as HTMLInputElement).setCustomValidity("")
                      }
                      helperText={ScamAmountMessage}
                      FormHelperTextProps={{
                        classes: {
                          root: "text-danger",
                        },
                      }}
                    />
                  </div>
                  <div className="bi-errorMessageBlock">
                    <TextField
                      required
                      type="date"
                      id="Betalingsdato-textbox"
                      label="Betalingsdato"
                      name="Betalingsdato"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      value={Betalingsdato}
                      onChange={(e) =>
                        setBetalingsdato(e.target.value as string)
                      }
                      onInvalid={(e) =>
                        (e.target as HTMLInputElement).setCustomValidity(
                          "Udfyld venligst dette felt"
                        )
                      }
                      onInput={(e) =>
                        (e.target as HTMLInputElement).setCustomValidity("")
                      }
                      helperText={dateValidationMessage}
                      FormHelperTextProps={{
                        classes: {
                          root: "text-danger",
                        },
                      }}
                    />
                  </div>
                  <FormControl fullWidth className="contact-select">
                    <InputLabel htmlFor="select-case-type">
                      Betalingsform *
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="contact-Betalingsform-type"
                      name="Betalingsform"
                      label={"Betalingsform *"}
                      fullWidth
                      className="dropdown"
                      style={{ minWidth: 120 }}
                      required
                      value={Betalingsform}
                      onChange={(e) =>
                        setBetalingsform(e.target.value as number)
                      }
                      onInvalid={(e) =>
                        (e.target as HTMLInputElement).setCustomValidity(
                          "Udfyld venligst dette felt"
                        )
                      }
                      onInput={(e) =>
                        (e.target as HTMLInputElement).setCustomValidity("")
                      }
                    >
                      <MenuItem value={0}>- Ingen -</MenuItem>
                      <MenuItem value={769120000}>MobilePay</MenuItem>
                      <MenuItem value={769120001}>Kontant</MenuItem>
                      <MenuItem value={769120002}>
                        {translation.BankoverPay}
                      </MenuItem>
                      <MenuItem value={769120003}>PayPal </MenuItem>
                      <MenuItem value={769120004}>Andet </MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    required
                    id="sellerName-textbox"
                    label={translation.sellerName}
                    name="SellerName"
                    variant="outlined"
                    fullWidth
                    value={SellerName}
                    onChange={(e) => setSellerName(e.target.value as string)}
                    onInvalid={(e) =>
                      (e.target as HTMLInputElement).setCustomValidity(
                        "Udfyld venligst dette felt"
                      )
                    }
                    onInput={(e) =>
                      (e.target as HTMLInputElement).setCustomValidity("")
                    }
                  />
                  <TextField
                    id="sellerAddress-textbox"
                    label={translation.sellerAddress}
                    name="SellerAddress"
                    variant="outlined"
                    fullWidth
                    value={SellerAddress}
                    onChange={(e) => setSellerAddress(e.target.value as string)}
                  />
                  <TextField
                    id="sellerPhone-textbox"
                    label={translation.sellerPhone}
                    name="SellerPhone"
                    variant="outlined"
                    fullWidth
                    value={sellerPhone}
                    onKeyPress={(e) =>
                      !/^[ +()0-9]+$/.test(e.key) && e.preventDefault()
                    }
                    onChange={(e) => setSellerPhone(e.target.value as string)}
                  />
                  <div className="bi-errorMessageBlock">
                    <TextField
                      id="sellerEmail-textbox"
                      label={translation.sellerEmail}
                      name="sellerEmail"
                      variant="outlined"
                      fullWidth
                      value={SellerEmail}
                      onChange={(e) => setSellerEmail(e.target.value as string)}
                      helperText={SellerEmailValidationMessage}
                      FormHelperTextProps={{
                        classes: {
                          root: "text-danger",
                        },
                      }}
                    />
                  </div>
                  <div className="bi-errorMessageBlock">
                    <TextField
                      id="Link til annoncen-textbox"
                      label="Link til annoncen"
                      name="LinktoAd"
                      variant="outlined"
                      fullWidth
                      value={LinktoAd}
                      onChange={(e) => setLinktoAd(e.target.value as string)}
                      helperText={LinktoAdValidationMessage}
                      FormHelperTextProps={{
                        classes: {
                          root: "text-danger",
                        },
                      }}
                    />
                  </div>
                  <div className="bi-errorMessageBlock">
                    <TextField
                      id="Link til profilen-textbox"
                      label="Link til profilen"
                      name="LinktoProfile"
                      variant="outlined"
                      fullWidth
                      value={LinktoProfile}
                      onChange={(e) =>
                        setLinktoProfile(e.target.value as string)
                      }
                      helperText={LinktoProfileValidationMessage}
                      FormHelperTextProps={{
                        classes: {
                          root: "text-danger",
                        },
                      }}
                    />
                  </div>
                </Card>
              )}
              <FormControl fullWidth className="contact-select mb-4">
                <InputLabel htmlFor="select-case-type">
                  {translation.platform}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="contact-select-type"
                  name="Platform"
                  label={"V�lg platform"}
                  fullWidth
                  className="dropdown"
                  style={{ minWidth: 120 }}
                  value={Platform}
                  onChange={(e) => {
                    e.preventDefault();
                    setPlatform(e.target.value as string);
                  }}
                >
                  <MenuItem value={"www.dba.dk"}>www.dba.dk</MenuItem>
                  <MenuItem value={"iOS app"}>iOS app</MenuItem>
                  <MenuItem value={"Android app"}> Android app</MenuItem>
                </Select>
              </FormControl>
              <TextField
                required
                id="Emne-textbox"
                label="Emne"
                name="Emne"
                variant="outlined"
                fullWidth
                value={Emne}
                onChange={(e) => setEmne(e.target.value as string)}
                onInvalid={(e) =>
                  (e.target as HTMLInputElement).setCustomValidity(
                    "Udfyld venligst dette felt"
                  )
                }
                onInput={(e) =>
                  (e.target as HTMLInputElement).setCustomValidity("")
                }
              />
              <TextField
                required
                id="Beskrivelse-textbox"
                label="Beskrivelse"
                name="Beskrivelse"
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                maxRows={4}
                value={Beskrivelse}
                onChange={(e) => setBeskrivelse(e.target.value as string)}
                onInvalid={(e) =>
                  (e.target as HTMLInputElement).setCustomValidity(
                    "Udfyld venligst dette felt"
                  )
                }
                onInput={(e) =>
                  (e.target as HTMLInputElement).setCustomValidity("")
                }
              />
            </Card>

            <Box className="d-flex mb-3">
              <Button
                variant="contained"
                component="label"
                className="upload-file"
              >
                {"Attachments"}
                <input
                  type="file"
                  accept="image/*,.csv,.pdf,.txt,.doc,.docx,.xml"
                  onChange={(e) => handleFilesAdded(e)}
                  hidden
                />
              </Button>
              {files && (
                <Typography className="font-16-bold line-height-50">
                  {files[0].name}
                </Typography>
              )}
            </Box>
            {isFileSizeExceeded && (
              <Box className="d-flex mb-3">
                <Typography className="font-16-bold line-height-50">
                  {translation.FileSizeExceed}
                </Typography>
              </Box>
            )}

            <Box className="form-checkbox">
              <Checkbox
                name="AcceptReturnPolicy"
                value={isAcceptReturnPolicy}
                onClick={() => setIsAcceptReturnPolicy(!isAcceptReturnPolicy)}
                color="primary"
                checked={isAcceptReturnPolicy}
                className="email-checkbox"
              />
              {translation.emailFormReturnTerms}
              <a href={Defines.POLICY_URL} className="ml-1" target="_blank">
                her
              </a>
            </Box>
            {ErrorMessage != "" && (
              <div className="captcha-error mb-2">
                <span>{ErrorMessage}</span>
              </div>
            )}
            <Box className="justify-content-end d-flex">
              <Button
                type="submit"
                variant="contained"
                className="ms-auto btn-button btn-blue"
                disabled={!isAcceptReturnPolicy}
              >
                {"Send"}
              </Button>
            </Box>
          </Box>
        )}
        {props.formSuccess && (
          <Box className="success-wrapper mt-4">
            <h4>
              <span className="checkSuccess"></span>
              {translation.Thankyou}
            </h4>
            <Typography>{translation.ReturnASAP}</Typography>
            <Button value="Luk" className="btn-success" onClick={onLukClick}>
              {translation.close}
            </Button>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(EmailForm);
